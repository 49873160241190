<template>
    <div class="products">
      <div class="page-header">
        <h1>{{ $t('Human Resource Management') }} > {{ $t('Payroll') }}</h1>
      </div>
      <div class="page-content">
        <div class="content-header">
          <h2>{{ $t('Payroll') }}</h2>
      </div>
        <div class="employee-list">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Staff ID</th>
                <th scope="col">Name</th>
                <th scope="col">Position</th>
                <th scope="col">Department</th>
                <th scope="col">Current Salary</th>
                <th scope="col">Transportation Allowance</th>
                <th scope="col">Bonus</th>
                <th scope="col">Others</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="employee in employees">
                <th scope="row">{{employee.employee_id}}</th>
                <td>{{employee.employee_name}}</td>
                <td>{{employee.position}}</td>
                <td>{{employee.department}}</td>
                <td>${{employee.base_salary_amount}}</td>
                <td>${{employee.transportation_allowance}}</td>
                <td>${{employee.bonus}}</td>
                <td>${{employee.other_salary}}</td>
                <td class="action">
                  <router-link :to="{name:'Staff', params: {id: employee.id}}">View</router-link>
                  <a href="#" class="delete-link" @click="deleteEmployee(employee.id)">Delete</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import Common from '@/lib/common';
  import Hr from '@/lib/hr';
  
  export default {
    name: 'Employees',
    data(){
      return {
        searchForm: {
          employee_id: '',
          position: '',
          department: '',
          phone_number: '',
        },
        originEmployees: [],
        employees: [],
      }
    },
    mounted(){
      this.loadEmployees();
    },
    methods:{
      async loadEmployees(){
        const loginInfo = Common.getLoginInfo();
        try{
          const employees = await Hr.loadAllEmployees(this.apiUrl, loginInfo);
          this.originEmployees = employees;
          this.employees = employees;
        }catch(err){
          console.log(err);
        }
      },
      resetFilter(){
        //Clean Filter
        for(let i = 0; i < Object.keys(this.searchForm).length; i++){
          const searchKey = Object.keys(this.searchForm)[i];
          this.searchForm[searchKey] = '';
        }
        this.employees = this.originEmployees;
      },
      searchEmployee(textSearchValue){
        this.searchForm['employee_name'] = textSearchValue;
        const searchFilter = this.searchForm;
        const newEmployees = Common.filterItems(searchFilter, this.originEmployees);
        this.employees = newEmployees;
      },
      formatDateTime(timestamp, format){
        const date = Common.formatDateTime(timestamp, format);
        return date;
      },
      async deleteEmployee(employeeId){
        const loginInfo = Common.getLoginInfo();
        try{
          await Hr.deleteEmployee(this.apiUrl, employeeId, loginInfo);
          const newEmployees = this.employees.filter(function(item) {
            return item.id !== employeeId;
          });
          this.employees = newEmployees;
        }catch(err){
          console.log(err);
        }
      },
    },
    computed: mapState({
     currentUser: state => state.currentUser,
     apiUrl: state => state.apiUrl,
   }),
  }
  </script>
  
  <style lang="scss">
  .view-toolbars{
    display: flex;
    position: relative;
    .fitler{
      flex: 1;
    }
    .el-icon-plus{
      font-size: 1.2em;
    }
  }
  .employee-list{
    .action{
      a{
        display: inline-block;
        margin-right: 10px;
      }
      .delete-link{
        color: red;
      }
    }
  }
  </style>
  